import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store/index'

const serviceapi = axios.create({
   //  baseURL: "http://localhost:8081",
    timeout: 20000,  // 超时设置 单位 ms，
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

serviceapi.interceptors.request.use(config => {
   //  console.log('axios 请求拦截')
   config.headers['Authorization'] = localStorage.getItem("token") // 请求头带上token
   let lang = store.getters.getLanguageHeader
   config.headers['Accept-Language'] = lang // 设置语言头
   return config
})

serviceapi.interceptors.response.use(response => {
      let res = response.data;
      console.log("response", res)
      console.log(res)
      if (res.code === 200) {
         return response.data
      } else {
          ElMessageBox.alert(res.msg ? res.msg : '系统异常！', '请求出错', {
              confirmButtonText: 'OK'
          })
         return Promise.reject(response.data.msg)
      }
   },
   error => {
      console.log(error)
      if(error.response.data) {
         error.message = error.response.data.msg
      }
      if(error.response.status === 401) {
         router.push("/login")
      }
      ElMessageBox.alert(error.message, {duration: 3 * 1000})
      return Promise.reject(error)
   }
)
export default serviceapi