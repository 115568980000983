import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import serviceapi from './api/api'
// import './api/mock/mock'

import 'amfe-flexible'

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn
})
app.use(store)
app.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    // console.log(key)
  app.component(key, component)
}
app.config.globalProperties.$axios = serviceapi

app.mount('#app')

//Vue.prototype.$axios = axios


// import './mock'
