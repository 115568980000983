export default {
    state: {
        zhcn: {
            systemErrorTitle: "系统出错",
            serviceErrorTitle:"请求提示"
    },
    en: {
        systemErrorTitle: "System error",
        serviceErrorTitle:"NOTICE"
    }
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
    modules: {

  }
}