export default {
    state: {
    zhcn: {
        pageTitle: 'Global-ptt',
        loginButton: '登录',
        placeHolder: '输入ICCID/SIM NO',
        searchButton: '搜索',
        iccid: 'ICCID',
            simno: 'SIM NO',
            activeTime: '激活时间',
            expireTime: '到期时间',
        cardStatus: '卡片状态',
            apn: 'APN',
        packageCountry: '套餐国家',
        dataTotal: '套餐总量',
        dataRemain: '剩余流量',
        buyNewCardUSA: 'Buy new cards（USA）',
        buyNewCardOther: 'Buy new cards（other countries)'
      },
    en: {
        pageTitle: 'Global-ptt',
        loginButton: 'Login',
        placeHolder: 'Enter ICCID/SIM NO',
        searchButton: 'Search',
        iccid: 'ICCID',
            simno: 'SIM NO',
            activeTime: 'Start time',
            expireTime: 'End time',
      cardStatus: 'State',
            apn: 'APN',
      packageCountry: 'Country',
        dataTotal: 'Data Total',
      dataRemain: 'Data Remaining',
        buyNewCardUSA: 'Buy new cards（USA）',
        buyNewCardOther: 'Buy new cards（other countries)'
    }
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
    modules: {

  }
}