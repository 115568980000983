import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/wrsearch',
    name: 'Layout',
    redirect: '/searchindex',
    component: () => import('@/views/SearchLayout.vue'),
    children: [
      {
        path: '/searchindex',
        name: 'searchindex',
        meta: {
          title: "iot global-ptt system"
        },
        component: () => import('@/views/search/IotSearch.vue')
      },
      {
        path: '/card/product',
        name: 'AnualProduct',
        meta: {
          title: "Global-ptt anual card"
        },
        component: () => import('@/views/product/ProductLayout.vue')
      }
    ]
  },
  {path: '/', name:'Index',redirect:'/searchindex'},
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('@/views/Login.vue'),
  // },

  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/error/NotFound.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
